import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDfOEVpims2reVYGryW7UT3rpT9vA1q2M8",
  authDomain: "frc604-batman.firebaseapp.com",
  projectId: "frc604-batman",
  storageBucket: "frc604-batman.appspot.com",
  messagingSenderId: "688676684507",
  appId: "1:688676684507:web:dfab241599144dc0d742fe",
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

// Connect to emulator in development
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  connectAuthEmulator(auth, `http://${window.location.hostname}:9099`);
  connectFirestoreEmulator(db, window.location.hostname, 8080);
}
