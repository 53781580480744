import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut as signOutFromFirebase,
} from "firebase/auth";
import {
  collection,
  doc,
  setDoc,
  CollectionReference,
} from "firebase/firestore";

import { auth, db } from "./firebase";

// Interfaces
export interface User {
  uid: string;
  name: string | null;
  email: string | null;
  photoURL: string | null;
  isAdmin: boolean;
  isAuthorized: boolean;
}

// Sign-in with Google
const googleProvider = new GoogleAuthProvider();
export const signIn = async () => {
  const res = await signInWithPopup(auth, googleProvider);
  const user = res.user;
  const newUser: Partial<User> = {
    uid: user.uid,
    name: user.displayName,
    email: user.email,
    photoURL: user.photoURL,
  };
  await setDoc(doc(db, "users", user.uid), newUser, { merge: true });
};

export const signOut = async () => {
  await signOutFromFirebase(auth);
};

// Users
export const usersCollection = () => {
  return collection(db, "users") as CollectionReference<Readonly<User>>;
};

export const giveUserAdmin = async (uid: string) => {
  const newUser: Partial<User> = {
    isAdmin: true,
  };
  await setDoc(doc(db, "users", uid), newUser, { merge: true });
};

export const removeUserAdmin = async (uid: string) => {
  const newUser: Partial<User> = {
    isAdmin: false,
  };
  await setDoc(doc(db, "users", uid), newUser, { merge: true });
};

export const giveUserAuth = async (uid: string) => {
  const newUser: Partial<User> = {
    isAuthorized: true,
  };
  await setDoc(doc(db, "users", uid), newUser, { merge: true });
};

export const removeUserAuth = async (uid: string) => {
  const newUser: Partial<User> = {
    isAuthorized: false,
  };
  await setDoc(doc(db, "users", uid), newUser, { merge: true });
};
