import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";

import { auth } from "./utils/firebase";

import AppBar from "./components/AppBar";

// Lazy load routes
const Home = React.lazy(() => import("./components/Home"));
const Battery = React.lazy(() => import("./components/Battery"));
const Users = React.lazy(() => import("./components/Users"));
const NewBatteryEvent = React.lazy(
  () => import("./components/NewBatteryEvent")
);
const QRCode = React.lazy(() => import("./components/QRCode"));
const NotFound = React.lazy(() => import("./components/NotFound"));
const EditBatteryEvent = React.lazy(
  () => import("./components/EditBatteryEvent")
);

const theme = createTheme({
  palette: {
    primary: {
      light: "#4d4d4d",
      main: "#212121",
      dark: "#171717",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#fbc436",
      main: "#fab604",
      dark: "#af7f02",
      contrastText: "#000",
    },
    mode: "dark",
  },
});

export default function App() {
  const [user, userLoading] = useAuthState(auth);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <AppBar user={user} userLoading={userLoading} />
        {userLoading ? (
          <div>LOADING</div>
        ) : user ? (
          <Suspense>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/battery/:battery_id" element={<Battery />} />
              <Route
                path="/battery/:battery_id/add_event"
                element={<NewBatteryEvent />}
              />
              <Route
                path="/battery/:battery_id/edit_event/:event_id"
                element={<EditBatteryEvent />}
              />
              <Route path="/users" element={<Users />} />
              <Route path="/qrcode" element={<QRCode />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        ) : (
          <Typography>Please log in to continue.</Typography>
        )}
      </BrowserRouter>
    </ThemeProvider>
  );
}
