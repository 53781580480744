const Logo = ({ dark, ...props }: { dark?: boolean; [props: string]: any }) => (
  <svg
    viewBox="0 0 208.445 89.285"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <use
      height="100%"
      width="100%"
      y={207.88}
      x={208.813}
      xlinkHref="#a"
      style={{
        fill: "#fab50a",
        fillOpacity: 1,
      }}
      transform="translate(-561.066 -339.926)"
    />
    <path
      d="m581.836 429.21 94.344-28.597-15.051-4.46 59.887-26.247-17.825-5.496 41.454-24.484-84.426 30.277 19.812 4.09-59.996 21.875 15.883 5.46zm0 0"
      style={{
        fill: "#fab604",
        fillOpacity: 1,
        fillRule: "evenodd",
        stroke: "none",
      }}
      transform="translate(-561.066 -339.926)"
    />
    <g
      style={{
        fill: dark ? "#000" : "#fff",
        fillOpacity: 1,
      }}
    >
      <path
        style={{
          fill: dark ? "#000" : "#fff",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
        }}
        d="M688.29 383.031c-.005 12.203-9.903 22.094-22.114 22.086-12.215-.012-22.102-9.914-22.082-22.12.004-12.212 9.902-22.095 22.117-22.09 12.207.011 22.094 9.913 22.078 22.124m9.106 11.57c.593-1.98 1.214-3.94 1.667-6.085 2.356-.735 4.68-1.512 7.09-2.188-.222-2.406-.113-5.723-.629-7.75-2.007-.539-4.382-.742-6.492-1.187-.457-2.086-1.047-4.063-1.496-6.172 1.516-1.973 3.207-3.75 4.918-5.48-1.34-2.227-2.777-4.376-4.258-6.473-1.965.941-4.222 1.57-6.34 2.355-1.488-1.785-3.375-3.207-4.718-5.113.707-2.031 1.14-4.38 1.808-6.45-2.199-1.242-4.527-2.355-6.715-3.609-1.578 1.578-4.609 4.88-4.609 4.88-2.008-.661-4.484-1.188-6.703-1.954-.535-2.113-1.04-3.828-1.488-6.164l-7.801-.184c-.457 2.094-.871 4.59-1.348 6.653-2 .566-4.144.957-6.047 1.62-1.586-1.616-3.086-3.304-4.875-4.738-2.047 1.356-4.543 2.208-6.433 3.743.73 2.086 1.316 4.3 2.105 6.336-1.726 1.449-3.215 3.191-5.094 4.468-1.921-.691-4-1.242-5.94-1.914-1.493 2.008-2.88 4.117-4.208 6.301 1.402 1.781 3.332 3.086 4.602 4.973-.563 2.3-1.106 4.605-1.836 6.707-2.192.57-4.317 1.207-6.325 1.988.055 2.637.426 5.094.258 7.606 1.907.457 4.145.64 5.989 1.144.66 2.121 1.039 4.477 1.722 6.563-1.629 1.664-3.355 3.207-5 4.847 1.43 2.113 2.5 4.528 3.992 6.586 1.766-.723 3.848-1.316 5.942-2.004 1.57 1.906 3.601 3.406 4.812 5.621-.597 1.887-1.375 3.582-1.883 5.575 1.934 1.5 4.172 2.738 6.168 4.191 2.067-1.344 3.454-3.469 5.504-4.82 2.059.543 4.407 1.5 6.68 2.21.465 2.008 1.035 3.915 1.371 6.028 3.242.152 5.059.062 8.074.281.567-2.137 1.047-4.656 1.563-6.469 2.219-.398 4.262-.996 6.418-1.476 1.566 1.473 2.855 3.191 4.496 4.601 2.16-1.476 4.84-2.336 6.84-3.972-.551-2.172-1.383-4.09-1.86-6.313 1.68-1.605 3.13-3.48 4.996-4.863 1.907.559 4.036 1.195 6.333 1.809 1.363-2.223 2.632-4.575 3.984-6.817-1.844-1.543-3.7-3.078-5.234-4.89"
        transform="translate(-561.066 -339.926)"
      />
      <path
        style={{
          fill: dark ? "#000" : "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        d="M590.766 383.563c-2.578 0-4.797.347-6.649 1.046-1.867.696-3.328 1.664-4.394 2.91v4.727c0 5.793.949 10.234 2.843 13.332 1.895 3.094 4.536 4.645 7.922 4.645 2.797 0 5.078-1.32 6.844-3.961 1.766-2.637 2.652-5.77 2.652-9.395 0-4.07-.855-7.304-2.574-9.703-1.707-2.402-3.922-3.601-6.644-3.601m3.695-41.672c3.164 0 5.98.265 8.445.8 2.465.532 5.188 1.387 8.168 2.567l-3.09 13.578c-2.578-.773-4.832-1.352-6.761-1.738-1.934-.387-4.149-.582-6.653-.582-4.414 0-7.957 1.554-10.625 4.664-2.668 3.113-3.945 7.644-3.836 13.605l.164.278c1.625-1.84 3.774-3.274 6.461-4.305 2.684-1.031 5.743-1.547 9.16-1.547 7.176 0 12.762 2.586 16.754 7.754 3.993 5.172 5.985 11.789 5.985 19.844 0 7.949-2.637 14.562-7.918 19.843-5.277 5.282-12.024 7.922-20.227 7.922-8.793 0-15.898-2.89-21.304-8.668-5.41-5.777-8.118-13.687-8.118-23.734V376c0-10.598 3.122-18.934 9.356-25.004 6.238-6.07 14.25-9.105 24.039-9.105M727.172 392.117h15.566v-24.836l-.328-.11-1.437 2.27zm34.226 0h8.114v14.352h-8.114v16.945h-18.66V406.47H711.61l-1.101-11.395 32.23-51.86v-.163h18.66zm0 0"
        transform="translate(-561.066 -339.926)"
      />
    </g>
  </svg>
);

export default Logo;
