import React from "react";
import { Link } from "react-router-dom";
import { User as FirebaseUser } from "firebase/auth";
import MUIAppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

import Logo from "./Logo";
import { signIn, signOut } from "../utils/auth";

export default function AppBar({
  user,
  userLoading,
}: {
  user: FirebaseUser | null | undefined;
  userLoading: boolean;
}) {
  const [anchorElUser, setAnchorElUser] = React.useState<Element | null>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MUIAppBar position="fixed">
        <Toolbar>
          <Logo height={24} onClick={() => navigate(`/`)} />

          <Typography
            id="app-title"
            variant="h6"
            color="text.primary"
            component={Link}
            to={`/`}
            sx={{ flexGrow: 1, ml: 2, textDecoration: "none" }}
          >
            BatMan
          </Typography>
          {!userLoading && !user && (
            <Button
              id="login-button"
              data-testid="login-button"
              variant="outlined"
              color="secondary"
              onClick={signIn}
            >
              Login
            </Button>
          )}
          {user && (
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 0 }}
              data-testid="avatar-button"
            >
              <Avatar
                alt={user.displayName ? user.displayName : undefined}
                src={user.photoURL ? user.photoURL : undefined}
              />
            </IconButton>
          )}
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={handleCloseUserMenu}>
              <Typography textAlign="center" onClick={signOut}>
                Logout
              </Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </MUIAppBar>
      <Toolbar />
    </Box>
  );
}
